<template>
  <v-row class="pa-0 ma-0">
    <v-col md="6">
      <div class="d-flex align-center">
        <span class="mt-1 mr-2">
          <img :src="budget" alt="" />
        </span>
        <strong>{{ $t("profile_page.billing_information_title") }}</strong>
      </div>
      <div style="max-height: 400px; overflow: auto">
        <div
          class="d-flex align-center justify-space-between mt-2"
          v-for="(paymentMethod, index) in defaultPaymentMethods"
          :key="index"
        >
          <div class="d-flex align-center">
            <img :src="cardIcon" alt="" />
            <span class="mx-5 font-family-raleway-bold">{{
              paymentMethod.number
            }}</span>
            <span class="font-weight-bold text-uppercase color-purple">{{
              paymentMethod.cardBrand
            }}</span>
          </div>
          <div class="default-box" v-if="paymentMethod.pM_ID === campaignPmId">
            {{ $t("details_page.selected") }}
          </div>
          <v-btn
            v-else
            class="font-family-raleway-bold text-none mb-1"
            @click="openDialog(paymentMethod.pM_ID)"
            :loading="loading"
            :disabled="status === 5"
            color="primary"
            height="32"
            outlined
            small
            text
          >
            {{ $t("details_page.select_billing") }}
          </v-btn>
        </div>
      </div>
    </v-col>
    <make-sure-select-for-biling
      :dialog="dialog"
      :is-selecting="loading"
      @cancel="dialog = false"
      @makeForBilling="defaultPaymentMethod"
    />
  </v-row>
</template>

<script>
import cardIcon from "@/assets/img/card.svg";
import { mapGetters } from "vuex";
import { authService } from "@/services/auth";
import budget from "@/assets/img/budjet.svg";

export default {
  name: "BillingSection",
  components: {
    MakeSureSelectForBiling: () =>
      import("@/components/dialogs/MakeSureSelectForBiling"),
  },
  props: {
    campaignPmId: String,
    campaignId: String,
    status: Number,
  },
  data() {
    return {
      cardIcon,
      isEditBilling: false,
      loading: false,
      budget,
      defaultPaymentMethods: [],
      dialog: false,
      paymentMethodId: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  async created() {
    await this.setPaymentMethods();
  },
  methods: {
    async setPaymentMethods() {
      const { data } = await authService.getUserInfo();
      const { stripePaymentMethods } = data;
      if (stripePaymentMethods.length) {
        this.defaultPaymentMethods = stripePaymentMethods;
      }
    },
    openDialog(paymentMethodId) {
      this.dialog = true;
      this.paymentMethodId = paymentMethodId;
    },
    discard() {
      this.isEditBilling = false;
    },
    async defaultPaymentMethod() {
      this.loading = true;
      try {
        await authService.setDefaultCampaignPaymentMethod(
          this.campaignId,
          this.paymentMethodId
        );
        await this.setPaymentMethods();
        this.$notify({
          group: "foo",
          type: "success",
          title: "Card was successfully changed!",
        });
        this.dialog = false;
        this.discard();
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode ? errCode : "Something went wrong",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
